$body-bg: #f2f2f2;
$body-color: #000;
$link-color: #f60;
$dark: #323232;
$darker: #2b2b2b;
$orange: #f4793d;
$muted-gray: #6c757d;
$enable-shadows: true;

$font-family-base: 'Montserrat', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.75;

$fa-font-path: "../assets/fonts";
