@import 'custom/_variables';
@import 'bootstrap/bootstrap';
@import 'fontawesome/fontawesome';
@import 'fontawesome/solid';
@import 'fontawesome/brands';

@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

body {
  font-weight: normal;
  font-style: normal;
  font-stretch: semi-expanded;
  letter-spacing: 0.2px;
  padding-top: 80px;
  .jumbotron {
    background-color: $white;
  }
  .text-orange {
    color: $orange;
  }
  .btn-orange {
    background-color: $orange;
    border-color: $orange;
    text-transform: uppercase;
    font-size: 14px;
    &:hover {
      background-color: $orange;
      border-color: $orange;
      opacity: 0.9;
    }
  }
  #content {
    #main-content .card {
      margin-bottom: 25px;
    }
    .card-body {
      padding: 20px 50px;
      a {
        color: $body-color;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &.btn-orange {
          color: $white;
        }
        &.btn-orange:hover {
          text-decoration: none;
        }
      }
      h3 {
        font-weight: bold;
      }
      .card-subtitle {
        padding: 0px 15px;
        span {
          padding-left: 10px;
          padding-right: 5px;
        }
      }
    }
  }
  .popover {
    .popover-body {
      a {
        font-size: 18px;
      }
      padding: 0 0.5rem;
    }
  }
  .icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
  #sidebar-title {
    background-color: $dark;
    margin: 0;
    span.title {
      font-size: 16px;
      text-transform: uppercase;
      color: $white;
      opacity: 0.5;
      margin: 0;
      line-height: initial;
      padding: 5px;
      i {
        padding-right: 9px;
      }
    }
  }
  #sidebar-show {
    display: none;
    position: absolute;
    top: 80px;
    right: 0px;
    background-color: $white;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px 0px 0px 5px;
    height: 36px;
    width: 32px;
    cursor: pointer;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
  }
  #navigation {
    padding: 0;
    .card-body {
      padding: 0;
    }
    .card {
      border: none;
      .card-header {
        padding: 5px;
        span {
          padding-right: 5px;
        }
        h2::before {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-size: 16px;
          opacity: 0.75;
          color: $dark;
          content: "\f078";
          position: absolute;
          top: 12px;
          right: 5px;
          padding: 5px;
          transition: all 0.5s;
          transform-style: preserve-3d;
        }
        &.active h2::before {
          position: absolute;
          top: 12px;
          right: 5px;
          padding: 5px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
          will-change: transform;
        }
        h2 {
          .btn-link {
            width: 100%;
            color: $dark;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            text-align: left;
            opacity: 0.75;
            padding-left: 0;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      .card-body {
        padding: 5px 0px;
        font-size: 12px;
      }
    }
    #tags-body {
      .card-body {
        padding: 10px 20px;
      }
    }
  }
  .tag {
    a {
      display: inline-block;
      padding: 0px 5px;
      border-radius: 3px;
      background-color: $body-bg;
      color: $dark;
      text-decoration: none;
      &:hover {
        text-decoration: none !important;
        background-color: $orange;
        color: $white !important;
      }
    }
    &.size-5 {
      font-size: 1.5rem;
    }
    &.size-4 {
      font-size: 1.25rem;
    }
    &.size-3 {
      font-size: 1.0rem;
    }
    &.size-2 {
      font-size: 0.75rem;
    }
    &.size-1 {
      font-size: 0.50rem;
    }
  }
  .tags {
    font-size: 0.5rem;
    color: $dark;
    font-weight: bold;
  }
  table {
    .thead-dark {
      background-color: $dark;
      th {
        color: $white;
        opacity: 0.5;
      }
    }
  }
  footer {
    margin-top: 50px;
    padding: 25px;
    .info-section {
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
      background-color: $darker;
      min-height: 100px;
      text-align: center;
      box-shadow: inset 0 1px 10px rgba(0,0,0,0.3),0 1px 0 rgba(255,255,255,0.1),0 -1px 0 rgba(0,0,0,0.5);
      p {
        padding: 0;
        margin: 0;
      }
      ul.social {
        list-style-type: none;
        display: inline;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          padding: 0 10px;
          a {
            font-size: 1.5rem;
            color: $white;
          }
        }
      }
    }
  }
  article {
    .reading-time {
      font-size: 0.75rem;
      color: $muted-gray;
      margin-top: 0px;
    }
    blockquote {
      font-size: 0.9rem;
      opacity: 0.8;
      border-left: 5px solid $muted-gray;
      padding-left: 10px;
      margin-left: 20px;
      color: $muted-gray;
      font-style: italic;
    }
    table {
      @extend .table, .table-bordered, .table-striped;
      thead {
        @extend .thead-dark;
      }
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .pagination {
    li.page-item.active a {
      border-color: $orange;
      background-color: $orange;
    }
  }
}
.no-transition {
  transition: none !important;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-wrap {
  white-space: nowrap;
}
